import React from "react";
import { Main } from "../layout/main";
import { Title } from "../components/Typography";
import Container from "../components/Container";

const IndexPage: React.FC = () => {
  return (
    <Main>
      <Container>
        <Title>404</Title>
      </Container>
    </Main>
  );
};

export default IndexPage;
